import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { HiOutlineSparkles } from "react-icons/hi2";
import { FaRegImage } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { HiChevronDown } from "react-icons/hi";
import { fetchWithTokenRefresh } from "../utils/fetchWithTokenRefresh";
import StyleSelector from "../components/gen/StyleSelector";
import EffectsSelector from "../components/gen/EffectsSelector";

const Generate = () => {
  const [inputValue, setInputValue] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState("realistic");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isImageMode, setIsImageMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedModel, setSelectedModel] = useState("Standard");
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [uploadedImageURL, setUploadedImageURL] = useState(null);
  const [selectedEffect, setSelectedEffect] = useState(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const fetchUserData = async () => {
      try {
        // Fetch credits
        const creditsResponse = await fetchWithTokenRefresh(
          "https://api.vidstudio.ai/user/credits",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const creditsData = await creditsResponse.json();
        if (creditsResponse.ok) {
          setCredits(creditsData.credits);
        } else {
          console.error("Failed to fetch credits:", creditsData.message);
          setCredits("Error");
        }

        // Fetch subscription info
        const subscriptionResponse = await fetchWithTokenRefresh(
          "https://api.vidstudio.ai/user/subscription",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const subscriptionData = await subscriptionResponse.json();
        if (subscriptionResponse.ok) {
          setSubscriptionStatus(subscriptionData.status);
          setStripeCustomerId(subscriptionData.stripeCustomerId);
        } else {
          console.error("Failed to fetch subscription:", subscriptionData.message);
          setSubscriptionStatus("inactive");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setCredits("Error");
        setSubscriptionStatus("Error");
      }
    };

    fetchUserData();

    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (uploadedImage) {
      const objectURL = URL.createObjectURL(uploadedImage);
      setUploadedImageURL(objectURL);

      // Clean up the object URL when the component unmounts or when a new image is uploaded
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [uploadedImage]);

  const handleEffectChange = (effect) => {
    // If clicking the same effect, just toggle it off
    if (effect === selectedEffect) {
      setSelectedEffect(null);
      setSelectedModel("Standard"); // Reset to default model
    } else {
      // Selecting a new effect
      setSelectedEffect(effect);
      setSelectedModel("Advanced");
    }
  };

  const handleCreate = async () => {
    const formData = new FormData();
    formData.append('model', selectedModel);

    // Ensure either input or effect is provided
    if (!inputValue.trim() && !selectedEffect) {
        alert("Please enter a prompt or select an effect.");
        return;
    }

    setIsLoading(true);
    try {
        if (uploadedImage) {
            formData.append('image', uploadedImage);
        } 
        
        if (selectedEffect) {
            formData.append('effect', selectedEffect);
        } else {
            formData.append('input', inputValue);
            formData.append('style', selectedStyle);
        }

        const response = await fetchWithTokenRefresh(
            "https://api.vidstudio.ai/trigger-clip",
            {
                method: "POST",
                body: formData,
            }
        );

        const data = await response.json();

        if (response.ok) {
            navigate("/dashboard/view");
        } else {
            alert(`Error: ${data.message}`);
        }
    } catch (error) {
        console.error("Error creating:", error);
        alert("Failed to create.");
    } finally {
        setIsLoading(false);
    }
  };

  const handleUpgrade = async () => {
    navigate("/profile");
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (files && files[0]) {
      setUploadedImage(files[0]);
      setIsImageMode(true);
    } else {
      setUploadedImage(null);
      setIsImageMode(false);
    }
    setIsDragging(false);
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    setUploadedImageURL(null);
    setIsImageMode(false);
    setSelectedStyle("realistic");
    
    // Reset any file input elements
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  // Helper function to render the credits or the Upgrade button
  const renderCreditsOrUpgrade = () => {
    if (credits === null) {
      // Loading state
      return (
        <div className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
          Loading...
        </div>
      );
    } else if (credits === 0) {
      // Upgrade button with blue gradient
      return (
        <button
          onClick={handleUpgrade}
          className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 text-white rounded-full px-3 py-1 text-sm font-semibold hover:from-blue-500 hover:to-blue-700 transition-all duration-300"
        >
          Upgrade
        </button>
      );
    } else if (credits === "Error") {
      // Error state
      return (
        <div className="bg-red-500 text-white rounded-full px-3 py-1 text-sm font-semibold">
          Error
        </div>
      );
    } else {
      // Display credits
      return (
        <div className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
          {credits}
        </div>
      );
    }
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {!isMobile && <Sidebar />}
      <div className="flex-1 p-6 md:p-8 lg:p-12 relative overflow-y-auto max-w-full">
        {!isMobile ? (
          <div className="absolute top-4 right-7 flex items-center space-x-4">
            {renderCreditsOrUpgrade()}
            <Link
              to="/profile"
              className="text-sm text-black font-semibold hover:underline"
            >
              Account
            </Link>
          </div>
        ) : (
          <div className="flex justify-between mb-4">
            <button
              onClick={() => navigate("/dashboard/view")}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Videos
            </button>
            <div className="flex items-center space-x-2">
              {renderCreditsOrUpgrade()}
              <button
                onClick={() => navigate("/profile")}
                className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
              >
                Account
              </button>
            </div>
          </div>
        )}
        <div className="overflow-visible">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            Create a new video
          </h1>

          {/* Path Selection */}
          <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 transition-all duration-300 hover:shadow-lg mb-6">
            <div className="flex items-center mb-4">
              <h3 className="text-lg font-bold text-gray-700">Choose your creation path</h3>
            </div>
            
            <div className="grid md:grid-cols-2 gap-4">
              {/* Text-to-Video Path */}
              <div 
                className={`p-4 md:p-6 rounded-xl border-2 cursor-pointer transition-all ${
                  !isImageMode && !selectedEffect 
                    ? 'border-blue-500 bg-blue-50' 
                    : 'border-gray-200 hover:border-gray-300'
                }`}
                onClick={() => {
                  setIsImageMode(false);
                  setSelectedEffect(null);
                  handleRemoveImage();
                }}
              >
                <div className="flex items-center gap-2 md:gap-3 mb-0 md:mb-3">
                  <span className="p-1.5 md:p-2 rounded-lg bg-blue-100">
                    <HiOutlineSparkles className="h-4 w-4 md:h-5 md:w-5 text-blue-600" />
                  </span>
                  <h4 className="font-semibold text-sm md:text-base">Text-to-Video</h4>
                </div>
                <p className="text-sm text-gray-600 md:block hidden mt-2">
                  Enter a text prompt and choose a style to generate your video
                </p>
              </div>

              {/* Image-to-Video Path */}
              <div 
                className={`p-4 md:p-6 rounded-xl border-2 cursor-pointer transition-all ${
                  (isImageMode || selectedEffect)
                    ? 'border-purple-500 bg-purple-50' 
                    : 'border-gray-200 hover:border-gray-300'
                }`}
                onClick={() => setIsImageMode(true)}
              >
                <div className="flex items-center gap-2 md:gap-3 mb-0 md:mb-3">
                  <span className="p-1.5 md:p-2 rounded-lg bg-purple-100">
                    <FaRegImage className="h-4 w-4 md:h-5 md:w-5 text-purple-600" />
                  </span>
                  <h4 className="font-semibold text-sm md:text-base">Image-to-Video</h4>
                </div>
                <p className="text-sm text-gray-600 md:block hidden mt-2">
                  Upload an image and apply effects to create your video
                </p>
              </div>
            </div>
          </div>

          {/* Conditional Content Based on Path */}
          {!isImageMode && !selectedEffect ? (
            <>
              {/* Text-to-Video Path Content */}
              <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 mb-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-gray-900 text-sm font-medium">
                      1
                    </span>
                    Enter your prompt
                  </h3>
                </div>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="w-full h-10 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Majestic dragon soaring over ancient ruins at sunset"
                />
              </div>

              {/* Style Selection */}
              <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 mb-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-gray-900 text-sm font-medium">
                      2
                    </span>
                    Choose your style
                  </h3>
                </div>
                <StyleSelector
                  selectedStyle={selectedStyle}
                  setSelectedStyle={setSelectedStyle}
                />
              </div>

              {/* Model Selection for Text-to-Video */}
              <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 mb-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-gray-900 text-sm font-medium">
                      3
                    </span>
                    Select video model
                  </h3>
                </div>
                <div className="relative w-72" ref={dropdownRef}>
                  <button
                    onClick={() => !selectedEffect && setIsModelDropdownOpen(!isModelDropdownOpen)}
                    className={`w-full flex items-center justify-between bg-white border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ${
                      !selectedEffect ? 'focus:border-black' : 'cursor-not-allowed'
                    }`}
                  >
                    <span className="text-gray-800">{selectedModel}</span>
                    {!selectedEffect && (
                      <HiChevronDown className={`h-5 w-5 text-gray-500 transition-transform ${
                        isModelDropdownOpen ? 'transform rotate-180' : ''
                      }`} />
                    )}
                  </button>
                  {isModelDropdownOpen && (
                    <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                      <div
                        className="p-3 hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setSelectedModel("Standard");
                          setIsModelDropdownOpen(false);
                        }}
                      >
                        <div className="font-medium text-gray-800">Standard</div>
                        <div className="text-xs text-gray-500"> (~1 min) Quick results with moderate fidelity and motion</div>
                      </div>
                      <div
                        className="p-3 hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setSelectedModel("Advanced");
                          setIsModelDropdownOpen(false);
                        }}
                      >
                        <div className="font-medium text-gray-800 flex items-center gap-2">
                          Advanced
                          <span className="text-[10px] px-2 py-0.5 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white font-medium">
                            New
                          </span>
                        </div>
                        <div className="text-xs text-gray-500">(~2 mins) Premium output with enhanced fidelity and motion</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Image-to-Video Path Content */}
              <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 mb-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-gray-900 text-sm font-medium">
                      1
                    </span>
                    Enter your prompt and upload an image
                  </h3>
                </div>
                <div
                  className={`relative border ${isDragging ? 'border-gray-400 bg-gray-100' : 'border-solid border-gray-300'} rounded-lg`}
                  onDrop={handleImageUpload}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                >
                  {isDragging && !uploadedImage && (
                    <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                      Drag and Drop image here
                    </div>
                  )}
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className={`w-full h-10 px-3 pr-12 text-gray-800 text-sm focus:outline-none bg-transparent ${
                      selectedEffect ? 'cursor-not-allowed' : ''
                    }`}
                    placeholder="Majestic dragon soaring over ancient ruins at sunset"
                    disabled={selectedEffect !== null}
                  />
                  <div className="absolute inset-y-0 right-3 flex items-center">
                    <div className="relative">
                      <label className="cursor-pointer">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="hidden"
                        />
                        <FaRegImage className="h-5 w-5 text-black" />
                      </label>
                      {selectedEffect && !uploadedImage && (
                        <div className="absolute bottom-full right-0 mb-2 w-48 bg-black text-white text-xs rounded-lg py-2 px-3 shadow-lg">
                          Upload an image to use the effect feature
                          <div className="absolute bottom-0 right-4 transform translate-y-1/2 rotate-45 w-2 h-2 bg-black"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {uploadedImage && (
                  <div className="relative mt-4 inline-block">
                    <img
                      src={uploadedImageURL}
                      alt="Uploaded Preview"
                      className="w-32 h-auto rounded-lg"
                    />
                    <IoIosCloseCircle
                      onClick={handleRemoveImage}
                      className="absolute -top-2 -right-2 text-black cursor-pointer bg-white rounded-full"
                      size={24}
                    />
                  </div>
                )}
              </div>

              {/* Effects Selection */}
              <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 mb-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-gray-900 text-sm font-medium">
                      2
                    </span>
                    Choose your effect (optional)
                  </h3>
                </div>
                <EffectsSelector
                  selectedEffect={selectedEffect}
                  setSelectedEffect={handleEffectChange}
                />
              </div>

              {/* Model Selection for Image-to-Video */}
              <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 mb-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-3">
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-gray-900 text-sm font-medium">
                      3
                    </span>
                    Select video model
                  </h3>
                </div>
                <div className="relative w-72" ref={dropdownRef}>
                  <button
                    onClick={() => !selectedEffect && setIsModelDropdownOpen(!isModelDropdownOpen)}
                    className={`w-full flex items-center justify-between bg-white border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ${
                      !selectedEffect ? 'focus:border-black' : 'cursor-not-allowed'
                    }`}
                  >
                    <span className="text-gray-800">{selectedModel}</span>
                    {!selectedEffect && (
                      <HiChevronDown className={`h-5 w-5 text-gray-500 transition-transform ${
                        isModelDropdownOpen ? 'transform rotate-180' : ''
                      }`} />
                    )}
                  </button>
                  {isModelDropdownOpen && (
                    <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                      <div
                        className="p-3 hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setSelectedModel("Standard");
                          setIsModelDropdownOpen(false);
                        }}
                      >
                        <div className="font-medium text-gray-800">Standard</div>
                        <div className="text-xs text-gray-500"> (~1 min) Quick results with moderate fidelity and motion</div>
                      </div>
                      <div
                        className="p-3 hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setSelectedModel("Advanced");
                          setIsModelDropdownOpen(false);
                        }}
                      >
                        <div className="font-medium text-gray-800 flex items-center gap-2">
                          Advanced
                          <span className="text-[10px] px-2 py-0.5 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white font-medium">
                            New
                          </span>
                        </div>
                        <div className="text-xs text-gray-500">(~2 mins) Premium output with enhanced fidelity and motion</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex items-end">
            <button
              onClick={handleCreate}
              className="bg-black text-white font-medium text-sm py-2.5 px-6 rounded-lg shadow-sm hover:bg-gray-900 transition-all duration-300 flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="grid min-h-[12px] w-full place-items-center">
                  <svg
                    className="text-gray-300 animate-spin"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="text-gray-900"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>
                  Generate Video
                  <HiOutlineSparkles className="h-3.5 w-3.5 ml-1.5 text-white" />
                </>
              )}
            </button>
            <span className="text-gray-500 text-[12px] ml-3">
              Cost: {selectedModel === "Standard" ? "100" : "150"} credits
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Generate;
