import React from 'react';

const Features = () => {
  return (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-4xl font-extrabold text-black">
            VidStudio <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">Features</span>
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Generate */}
          <div className="bg-white rounded-2xl p-6 shadow-lg flex flex-col justify-between h-full">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Text to Video</h3>
              <p className="text-gray-600 text-sm mb-6">
                Enter a prompt, and let VidStudio AI turn your vision into reality.
              </p>
            </div>
            <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden bg-gray-100">
              <img 
                src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/Create.png" 
                alt="Generate" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Customize */}
          <div className="bg-white rounded-2xl p-6 shadow-lg flex flex-col justify-between h-full">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Image to Video</h3>
              <p className="text-gray-600 text-sm mb-6">
                Turn any image into an awesome video!
              </p>
            </div>
            <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden bg-gray-100">
              <video 
                src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/Create%20(1).mp4" 
                className="w-full h-full object-cover"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </div>

          {/* Download */}
          <div className="bg-white rounded-2xl p-6 shadow-lg flex flex-col justify-between h-full">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Popular Styles</h3>
              <p className="text-gray-600 text-sm mb-6">
                We keep up with all the trends to help you create viral content!
              </p>
            </div>
            <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden bg-gray-100">
              <img 
                src="https://delivery626.nyc3.cdn.digitaloceanspaces.com/Create%20(1).png" 
                alt="Download" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features; 