/* global fbq */
/* global ttq */

import React, { useEffect } from 'react';
import HeroSection from '../components/Landing/HeroSection';
import FAQs from '../components/Landing/FAQs';
import Footer from '../components/Landing/Footer';
import VideoSlider from '../components/Landing/VideoSlider';
import VidStats from '../components/Landing/VidStats.js';
import { CTA1, CTA2, CTA3 } from '../components/Landing/CTA.js';
import Header from '../components/Header.js';
import Pricing from '../components/Pricing.js';
import heroBg from '../icons/heroBg.png';
import Features from '../components/Landing/Features';

const LandingPage = () => {
  useEffect(() => {
    if (typeof fbq === 'function') {
      fbq('track', 'PageView');
    }
    if (window.ttq) {
      window.ttq.track('ViewContent', {
        contents: [{
          content_id: "101",
          content_type: "product",
          content_name: "StudyBot"
        }],
        value: 0,
        currency: "USD"
      });
    }
  }, []);

  return (
    <div className="relative bg-white">
      <div className="p-3 md:p-6 lg:p-8 h-[100dvh] md:h-auto"> {/* Adjust height for desktop */}
        <div 
          className="relative h-full md:h-auto md:min-h-[700px] max-w-[430px] md:max-w-none mx-auto rounded-[2rem] overflow-hidden"
          style={{
            backgroundImage: `url(${heroBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: .9
          }}
        >
          {/* Grid Pattern with Gradient Overlay */}
          <svg 
            aria-hidden="true" 
            className="pointer-events-none absolute inset-0 h-full w-full"
          >
            <defs>
              <pattern 
                id="grid" 
                width="60" 
                height="60" 
                patternUnits="userSpaceOnUse"
                x="0" 
                y="0"
              >
                <path 
                  d="M.5 80V.5H80" 
                  fill="none" 
                  stroke="rgba(0,0,0,0.05)" 
                  strokeDasharray="0"
                />
              </pattern>
            </defs>
            <rect 
              width="100%" 
              height="100%" 
              fill="url(#grid)" 
            />
          </svg>

          {/* Content */}
          <div className="relative z-10 h-full">
            <Header />
            <HeroSection />
          </div>
        </div>
      </div>
      <VideoSlider />
      <Features />
      <CTA1 />
      <VidStats />
      <div id="pricing-sec">
        <Pricing />
      </div>
      <CTA2 />
      <div id="faqs">
        <FAQs />
      </div>
      <CTA3 />
      <Footer />
    </div>
  );
};

export default LandingPage;
