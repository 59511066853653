import React from 'react';

const effects = [
  { 
    label: 'Muscular Flex', 
    value: 'muscle', 
    image: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/video_qm3sdmm9zim5es0rg0-ezgif.com-video-to-gif-converter.gif'
  }
];

const EffectsSelector = ({ selectedEffect, setSelectedEffect }) => {
  return (
    <div className="flex gap-4 overflow-x-auto pb-1 scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-100">
      <div className="flex gap-6 p-2">
        {effects.map((effect) => (
          <div 
            key={effect.value} 
            className={`flex-none w-40 h-48 relative group flex flex-col gap-3 cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105 ${
              selectedEffect === effect.value 
                ? 'ring-2 ring-purple-500 rounded-xl shadow-lg' 
                : 'hover:shadow-lg'
            }`}
            onClick={() => setSelectedEffect(effect.value)}
          >
            <div className="absolute inset-0 rounded-xl overflow-hidden">
              <img 
                loading="lazy" 
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105" 
                src={effect.image} 
                alt={effect.label} 
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <span className="text-white font-medium text-sm tracking-wide">
                {effect.label}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EffectsSelector; 